import { makeStyles } from '@material-ui/styles';
import AppNavbar from 'components/app-navbar';
import Loading from 'components/loading';
import NoteBottomNavbar from 'components/note-bottom-navbar';
import { AuthContext } from 'config/auth.context';
import { auth } from 'config/firebase';
import { User } from 'firebase';
import { AuthState } from 'models/auth';
import * as React from 'react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { buildTagListAsync } from 'state/actions/note';
import { dispatch } from 'config/store';

const LogInPage = lazy(() => import('features/log-in/log-in.page'));
const HomeRouter = lazy(() => import('features/home/home.router'));
const NoteRouter = lazy(() => import('features/note/note.router'));

interface AppContainerProps {}

const useStyles = makeStyles(theme => ({
    layout: {
        marginTop: theme.spacing.unit * 10,
        marginBottom: theme.spacing.unit * 18,
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

const AppContainer: React.FunctionComponent<AppContainerProps> = () => {
    const classes = useStyles();
    const [authState, setAuthState] = useState<AuthState>({ isSignedIn: null, user: null });
    useEffect(() => {
        return auth().onAuthStateChanged((user: User) => {
            if (authState.isSignedIn === null || user !== authState.user) {
                setAuthState({ isSignedIn: !!user, user: user ? user : null });
            }
        });
    }, [JSON.stringify(authState)]);

    if (authState.isSignedIn === null) {
        return null;
    }

    dispatch(buildTagListAsync.action());

    return (
        <div className={classes.layout}>
            {authState.isSignedIn ? (
                <>
                    <AppNavbar />
                    <Suspense fallback={<Loading />}>
                        <AuthContext.Provider value={authState}>
                            <Switch>
                                <Route exact path="/" render={() => <HomeRouter />} />
                                <Route path="/note" render={() => <NoteRouter />} />
                            </Switch>
                        </AuthContext.Provider>
                    </Suspense>
                    <NoteBottomNavbar />
                </>
            ) : (
                <Suspense fallback={<Loading />}>
                    <LogInPage />
                </Suspense>
            )}
        </div>
    );
};

export default AppContainer;
