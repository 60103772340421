export type NoteId = string;
export type TopicId = string;
export type LinkId = string;
export type TagId = string;

export interface Note {
    id: NoteId;
    title: string;
    episodeNumber: string;
    recordingDatetime: string;
    showSlug: string;
    topics: Topic[];
}

export interface Topic {
    id: TopicId;
    name: string;
    notes: string;
    discussedDatetime: string;
    links: Link[];
    tags: Tag[];
}

export interface Link {
    id: LinkId;
    title: string;
    url: string;
}

export interface Tag {
    id: TagId;
    label: string;
}

export enum ViewMode {
    Edit = 'edit',
    ShowTime = 'show-time',
    PostShow = 'post-show',
    Raw = 'raw',
}

export enum PostShowTabs {
    Metadata = 'metadata',
    FullDescription = 'full-description',
    Summary = 'summary',
    Tags = 'tags',
}

export enum EditTabs {
    Metadata = 'metadata',
    Topics = 'topics',
}
