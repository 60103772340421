import { Action, AnyAction, applyMiddleware, compose, createStore, Dispatch } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { AppState, reducer } from 'state/root-reducer';
import { addActionToReplayAction } from 'state/actions/replay';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const thunk: ThunkMiddleware<AppState, AnyAction> = thunkMiddleware;
export const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export const dispatch: Dispatch<Action<any>> & ThunkDispatch<AppState, undefined, AnyAction> =
    store.dispatch;

export function dispatchReplayable(action: AnyAction) {
    dispatch(addActionToReplayAction({ action }));
    dispatch(action);
}
