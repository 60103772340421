import { db } from 'config/firebase';
import cloneDeep from 'lodash/cloneDeep';
import { Note, ViewMode } from 'models/notes';
import { AppState } from 'state/root-reducer';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { getTitleCase } from 'utils/note';
import { dispatchReplayable } from 'config/store';

const noteActionCreator = actionCreatorFactory('NOTE');
const asyncNoteActionCreator = asyncFactory<AppState>(noteActionCreator);

export const clearCurrentNoteAction = noteActionCreator('CLEAR');

export const commitNoteAsync = asyncNoteActionCreator<void, void, AppState>(
    'COMMIT',
    async (_, dispatch, getState) => {
        const { note } = getState();
        const newNote = cloneDeep(note);

        const noteId = newNote.id;
        delete newNote.id;

        await db
            .collection('notes')
            .doc(noteId)
            .set(newNote);
    }
);

export const resetCommitNoteStatusAction = noteActionCreator('RESET_COMMIT_NOTE_STATUS');

export const saveIncomingNoteAction = noteActionCreator<{
    note: Note;
    id: string;
    initial?: boolean;
}>('SAVE_INCOMING_NOTE');

export const acceptIncomingNoteAction = noteActionCreator('ACCEPT_INCOMING_NOTE');

export const updateMetadataAction = noteActionCreator<{ targetId: string; targetValue: string }>(
    'UPDATE_METADATA'
);

export const undoNoteChangesAction = noteActionCreator('UNDO_NOTE_CHANGES');

export const setViewModeAction = noteActionCreator<{ mode: ViewMode }>('SET_VIEW_MODE');

export const fetchTitleCaseSuccessAction = noteActionCreator<{ title: string }>(
    'FETCH_TITLE_CASE_SUCCCESS'
);

export const fetchTitleCaseAsync = asyncNoteActionCreator<void, void, AppState>(
    'FETCH_TITLE_CASE',
    async (params, _, getState) => {
        const { title } = getState().note;
        try {
            dispatchReplayable(fetchTitleCaseSuccessAction({ title: await getTitleCase(title) }));
        } catch (e) {
            dispatchReplayable(fetchTitleCaseSuccessAction({ title }));
        }
    }
);

export const updateTagListAction = noteActionCreator<{ tags: string[] }>('UPDATE_TAG_LIST');

export const buildTagListAsync = asyncNoteActionCreator<void, void, AppState>(
    'BUILD_TAG_LIST',
    async (_, dispatch) => {
        await db.collection('notes').onSnapshot(querySnapshot => {
            const tags: string[] = [];
            querySnapshot.forEach(doc => {
                const note = doc.data() as Note;
                if (Array.isArray(note.topics)) {
                    note.topics.forEach(topic => {
                        if (Array.isArray(topic.tags)) {
                            topic.tags.forEach(tag => {
                                if (!tags.includes(tag.label)) {
                                    tags.push(tag.label);
                                }
                            });
                        }
                    });
                }
            });
            dispatch(updateTagListAction({ tags }));
        });
    }
);
