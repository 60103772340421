import { compareAsc } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { Note, Topic, ViewMode } from 'models/notes';
import { FetchStatus } from 'models/requests';
import { Action, AnyAction } from 'redux';
import {
    acceptIncomingNoteAction,
    clearCurrentNoteAction,
    commitNoteAsync,
    fetchTitleCaseSuccessAction,
    resetCommitNoteStatusAction,
    saveIncomingNoteAction,
    setViewModeAction,
    undoNoteChangesAction,
    updateMetadataAction,
    updateTagListAction,
} from 'state/actions/note';
import { markRecordingStartAction, toggleTopicStartAction } from 'state/actions/show-time';
import {
    addLinkAsync,
    addLinkSuccessAction,
    addTagAction,
    addTopicAction,
    buildNewLinkStateAction,
    deleteLinkAction,
    deleteTopicAction,
    fetchLinkTitleAsync,
    fetchLinkTitleSuccessAction,
    hideEditLinkModalAction,
    hideReorderModalAction,
    removeTagAction,
    reorderTopicAction,
    resetFetchLinkTitleStatusAction,
    setTopicDiscussedDateAction,
    showEditLinkModalAction,
    showReorderModalAction,
    updateLinkAction,
    updateNewLinkAction,
    updateNewTopicAction,
    updateTopicAction,
} from 'state/actions/topic';
import { isType } from 'typescript-fsa';
import { buildNewLinks } from 'utils/link';
import {
    addActionToReplayAction,
    clearReplayActionsAction,
    mergeIncomingChangesAsync,
} from 'state/actions/replay';

export interface NewLinks {
    [topicId: string]: {
        url: string;
        ready: boolean;
    };
}

export interface ShowEditLink {
    linkId: string;
    topicId: string;
}

export interface AppState {
    hasUnmergedChanges: boolean;
    committedNote: Note;
    note: Note;
    newTopic: string;
    newLinks: NewLinks;
    showReorder: boolean;
    showEditLink: ShowEditLink;
    committingNoteStatus: FetchStatus;
    fetchingLinkTitleStatus: FetchStatus;
    replayStatus: boolean;
    tags: string[];
    replayActions: AnyAction[];
    viewMode: ViewMode;
}

export const initialAppState: AppState = {
    hasUnmergedChanges: false,
    committedNote: {} as Note,
    note: {} as Note,
    newTopic: '',
    newLinks: {} as NewLinks,
    showReorder: false,
    showEditLink: { linkId: null, topicId: null },
    committingNoteStatus: FetchStatus.Idle,
    fetchingLinkTitleStatus: FetchStatus.Idle,
    replayStatus: false,
    tags: [],
    replayActions: [],
    viewMode: ViewMode.Edit,
};

export const reducer = (state: AppState = initialAppState, action: Action): AppState => {
    const mutableState = cloneDeep(state);

    // Replay Actions
    if (isType(action, addActionToReplayAction)) {
        mutableState.replayActions.push(action.payload.action);
        mutableState.replayActions.forEach(action => {
            console.log(
                `${action.type}${action.payload ? ': ' + JSON.stringify(action.payload) : ''}`
            );
        });

        return {
            ...state,
            replayActions: mutableState.replayActions,
        };
    }

    if (isType(action, clearReplayActionsAction)) {
        return {
            ...state,
            replayActions: [],
        };
    }

    if (isType(action, mergeIncomingChangesAsync.async.started)) {
        return {
            ...state,
            replayStatus: true,
        };
    }

    if (isType(action, mergeIncomingChangesAsync.async.done)) {
        return {
            ...state,
            replayStatus: false,
        };
    }

    // Note Actions
    if (isType(action, clearCurrentNoteAction)) {
        return {
            ...cloneDeep(initialAppState),
            viewMode: state.viewMode,
        };
    }

    if (isType(action, commitNoteAsync.async.started)) {
        return {
            ...state,
            committingNoteStatus: FetchStatus.Active,
        };
    }

    if (isType(action, commitNoteAsync.async.done)) {
        return {
            ...state,
            committingNoteStatus: FetchStatus.Idle,
            replayActions: [],
        };
    }

    if (isType(action, commitNoteAsync.async.failed)) {
        return {
            ...state,
            committingNoteStatus: FetchStatus.Error,
        };
    }

    if (isType(action, saveIncomingNoteAction)) {
        const newNote = cloneDeep(action.payload.note);
        newNote.id = action.payload.id;
        const note =
            action.payload.initial ||
            !state.replayActions.length ||
            state.committingNoteStatus === FetchStatus.Active
                ? newNote
                : state.note;

        return {
            ...state,
            hasUnmergedChanges:
                state.committingNoteStatus !== FetchStatus.Active && !!state.replayActions.length,
            committedNote: newNote,
            note,
            newLinks: buildNewLinks(note, mutableState.newLinks),
        };
    }

    if (isType(action, acceptIncomingNoteAction)) {
        return {
            ...state,
            hasUnmergedChanges: false,
            note: state.committedNote,
            newLinks: buildNewLinks(state.committedNote, mutableState.newLinks),
        };
    }

    if (isType(action, resetCommitNoteStatusAction)) {
        return {
            ...state,
            committingNoteStatus: FetchStatus.Idle,
        };
    }

    if (isType(action, updateMetadataAction)) {
        const { targetId, targetValue } = action.payload;
        mutableState.note[targetId] = targetValue;
        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, setViewModeAction)) {
        return {
            ...state,
            viewMode: action.payload.mode,
        };
    }

    if (isType(action, fetchTitleCaseSuccessAction)) {
        mutableState.note.title = action.payload.title;

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, updateTagListAction)) {
        return {
            ...state,
            tags: action.payload.tags,
        };
    }

    // Topic Actions
    if (isType(action, buildNewLinkStateAction)) {
        return {
            ...state,
            newLinks: buildNewLinks(mutableState.note, mutableState.newLinks),
        };
    }

    if (isType(action, updateNewTopicAction)) {
        const { targetValue } = action.payload;
        return {
            ...state,
            newTopic: targetValue,
        };
    }

    if (isType(action, undoNoteChangesAction)) {
        return {
            ...initialAppState,
            committedNote: mutableState.committedNote,
            note: mutableState.committedNote,
            newLinks: buildNewLinks(mutableState.committedNote, {}),
            tags: mutableState.tags,
        };
    }

    if (isType(action, addTopicAction)) {
        const { topicId } = action.payload;

        if (!Array.isArray(mutableState.note.topics)) {
            mutableState.note.topics = [];
        }
        const newTopic: Topic = {
            id: topicId,
            name: state.newTopic,
            discussedDatetime: null,
        } as Topic;
        mutableState.note.topics.push(newTopic);
        return {
            ...state,
            note: mutableState.note,
            newTopic: '',
            newLinks: buildNewLinks(mutableState.note, state.newLinks),
        };
    }

    if (isType(action, updateTopicAction)) {
        const { targetId, targetValue, topicId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);

        mutableState.note.topics[topicIndex][targetId] = targetValue;

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, deleteTopicAction)) {
        const { topicId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        mutableState.note.topics.splice(topicIndex, 1);
        return {
            ...state,
            note: mutableState.note,
            newLinks: buildNewLinks(mutableState.note, state.newLinks),
        };
    }

    if (isType(action, setTopicDiscussedDateAction)) {
        const { topicId, discussedDate } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        mutableState.note.topics[topicIndex].discussedDatetime = discussedDate;

        const nullTopics = mutableState.note.topics.filter(
            (topic: Topic) => topic.discussedDatetime === null
        );
        const discussedTopics = mutableState.note.topics.filter(
            (topic: Topic) => topic.discussedDatetime !== null
        );
        discussedTopics.sort((topic1: Topic, topic2: Topic) =>
            compareAsc(new Date(topic1.discussedDatetime), new Date(topic2.discussedDatetime))
        );

        mutableState.note.topics = [...discussedTopics, ...nullTopics];

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, reorderTopicAction)) {
        const { topicId, delta } = action.payload;
        const startIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        let endIndex = startIndex + delta;

        if (endIndex < 0) {
            endIndex = 0;
        } else if (endIndex >= state.note.topics.length) {
            endIndex = state.note.topics.length - 1;
        }

        const movingTopic = mutableState.note.topics.splice(startIndex, 1);
        mutableState.note.topics.splice(endIndex, 0, movingTopic[0]);

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, showReorderModalAction)) {
        return {
            ...state,
            showReorder: true,
        };
    }

    if (isType(action, hideReorderModalAction)) {
        return {
            ...state,
            showReorder: false,
        };
    }

    if (isType(action, updateNewLinkAction)) {
        const { targetValue, topicId } = action.payload;

        const mutableNewLinks = { ...mutableState.newLinks };
        mutableNewLinks[topicId] = { url: targetValue, ready: true };

        return {
            ...state,
            newLinks: mutableNewLinks,
        };
    }

    if (isType(action, addLinkAsync.async.started)) {
        const { topicId } = action.payload;
        mutableState.newLinks[topicId].ready = false;

        return {
            ...state,
            newLinks: mutableState.newLinks,
            fetchingLinkTitleStatus: FetchStatus.Active,
        };
    }

    if (isType(action, addLinkSuccessAction)) {
        const { linkId, topicId, url, title } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);

        let linkExists = false;

        if (Array.isArray(mutableState.note.topics[topicIndex].links)) {
            linkExists = mutableState.note.topics[topicIndex].links
                .map(link => link.url)
                .includes(url);
        } else {
            mutableState.note.topics[topicIndex].links = [];
        }

        if (!linkExists) {
            mutableState.note.topics[topicIndex].links.push({ id: linkId, url, title });
        }

        mutableState.newLinks[topicId].url = '';
        mutableState.newLinks[topicId].ready = true;

        return {
            ...state,
            note: mutableState.note,
            newLinks: mutableState.newLinks,
            fetchingLinkTitleStatus: FetchStatus.Idle,
        };
    }

    if (isType(action, addLinkAsync.async.failed)) {
        return {
            ...state,
            fetchingLinkTitleStatus: FetchStatus.Error,
        };
    }

    if (isType(action, updateLinkAction)) {
        const { targetId, targetValue, topicId, linkId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        const linkIndex = state.note.topics[topicIndex].links.findIndex(link => link.id === linkId);

        if (linkIndex === -1) {
            return state;
        }

        mutableState.note.topics[topicIndex].links[linkIndex][targetId] = targetValue;

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, deleteLinkAction)) {
        const { topicId, linkId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        const linkIndex = state.note.topics[topicIndex].links.findIndex(link => link.id === linkId);

        if (linkIndex === -1) {
            return state;
        }

        mutableState.note.topics[topicIndex].links.splice(linkIndex, 1);

        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, showEditLinkModalAction)) {
        const { topicId, linkId } = action.payload;

        return {
            ...state,
            showEditLink: { topicId, linkId },
        };
    }

    if (isType(action, hideEditLinkModalAction)) {
        return {
            ...state,
            showEditLink: { ...initialAppState.showEditLink },
        };
    }

    if (isType(action, fetchLinkTitleAsync.async.started)) {
        return {
            ...state,
            fetchingLinkTitleStatus: FetchStatus.Active,
        };
    }

    if (isType(action, fetchLinkTitleSuccessAction)) {
        const { topicId, linkId, title } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        const linkIndex = state.note.topics[topicIndex].links.findIndex(link => link.id === linkId);

        mutableState.note.topics[topicIndex].links[linkIndex].title = title;

        return {
            ...state,
            note: mutableState.note,
            fetchingLinkTitleStatus: FetchStatus.Idle,
        };
    }

    if (isType(action, fetchLinkTitleAsync.async.failed)) {
        return {
            ...state,
            fetchingLinkTitleStatus: FetchStatus.Error,
        };
    }

    if (isType(action, resetFetchLinkTitleStatusAction)) {
        return {
            ...state,
            fetchingLinkTitleStatus: FetchStatus.Idle,
        };
    }

    if (isType(action, addTagAction)) {
        const { tag, topicId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);

        let tagExists = false;

        if (Array.isArray(mutableState.note.topics[topicIndex].tags)) {
            tagExists = mutableState.note.topics[topicIndex].tags
                .map(tag => tag.label)
                .includes(tag.label);
        } else {
            mutableState.note.topics[topicIndex].tags = [];
        }

        if (!tagExists) {
            mutableState.note.topics[topicIndex].tags.push(tag);
        }

        if (!mutableState.tags.includes(action.payload.tag.label)) {
            mutableState.tags.push(action.payload.tag.label);
        }

        return {
            ...state,
            note: mutableState.note,
            tags: mutableState.tags,
        };
    }

    if (isType(action, removeTagAction)) {
        const { tagId, topicId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);
        const tagIndex = state.note.topics[topicIndex].tags.findIndex(tag => tag.id === tagId);

        if (tagIndex === -1) {
            return state;
        }

        mutableState.note.topics[topicIndex].tags.splice(tagIndex, 1);

        return {
            ...state,
            note: mutableState.note,
        };
    }

    // Show Time Actions
    if (isType(action, markRecordingStartAction)) {
        mutableState.note.recordingDatetime = action.payload.date;
        return {
            ...state,
            note: mutableState.note,
        };
    }

    if (isType(action, toggleTopicStartAction)) {
        const { topicId } = action.payload;
        const topicIndex = state.note.topics.findIndex(topic => topic.id === topicId);

        mutableState.note.topics[topicIndex].discussedDatetime = mutableState.note.topics[
            topicIndex
        ].discussedDatetime
            ? null
            : new Date().toISOString();

        const nullTopics = mutableState.note.topics.filter(
            (topic: Topic) => topic.discussedDatetime === null
        );
        const discussedTopics = mutableState.note.topics.filter(
            (topic: Topic) => topic.discussedDatetime !== null
        );
        discussedTopics.sort((topic1: Topic, topic2: Topic) =>
            compareAsc(new Date(topic1.discussedDatetime), new Date(topic2.discussedDatetime))
        );

        mutableState.note.topics = [...discussedTopics, ...nullTopics];

        return {
            ...state,
            note: mutableState.note,
        };
    }

    return state;
};
