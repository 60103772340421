import cloneDeep from 'lodash/cloneDeep';
import { LinkId, Tag, TagId, TopicId } from 'models/notes';
import { AppState } from 'state/root-reducer';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { getLinkTitle } from 'utils/link';
import { dispatchReplayable } from 'config/store';

export const topicActionCreator = actionCreatorFactory('TOPIC');
const asyncTopicActionCreator = asyncFactory<AppState>(topicActionCreator);

export const buildNewLinkStateAction = topicActionCreator('BUILD_NEW_LINK_STATE');

export const updateNewTopicAction = topicActionCreator<{ targetValue: string }>('UPDATE_NEW_TOPIC');

export const addTopicAction = topicActionCreator<{ topicId: TopicId }>('ADD_TOPIC');

export const updateTopicAction = topicActionCreator<{
    targetId: string;
    targetValue: string;
    topicId: TopicId;
}>('UPDATE_TOPIC');

export const deleteTopicAction = topicActionCreator<{ topicId: TopicId }>('DELETE_TOPIC');

export const setTopicDiscussedDateAction = topicActionCreator<{
    topicId: TopicId;
    discussedDate: string;
}>('SET_TOPIC_DISCUSSED_DATE');

export const reorderTopicAction = topicActionCreator<{
    topicId: TopicId;
    delta: number;
}>('REORDER_TOPICS');

export const showReorderModalAction = topicActionCreator('SHOW_REORDER_MODAL');

export const hideReorderModalAction = topicActionCreator('HIDE_REORDER_MODAL');

export const updateNewLinkAction = topicActionCreator<{ targetValue: string; topicId: TopicId }>(
    'UPDATE_NEW_LINK'
);

export const addLinkSuccessAction = topicActionCreator<{
    linkId: LinkId;
    topicId: TopicId;
    url: string;
    title: string;
}>('ADD_LINK_SUCCESS');

export const addLinkAsync = asyncTopicActionCreator<
    { linkId: LinkId; topicId: TopicId; url: string },
    void,
    AppState
>('ADD_LINK', async params => {
    const { linkId, topicId, url } = params;
    try {
        dispatchReplayable(
            addLinkSuccessAction({
                linkId: linkId,
                topicId,
                url,
                title: await getLinkTitle(url),
            })
        );
    } catch (e) {
        dispatchReplayable(
            addLinkSuccessAction({
                linkId: linkId,
                topicId,
                url,
                title: '',
            })
        );
    }
});

export const updateLinkAction = topicActionCreator<{
    targetId: string;
    targetValue: string;
    topicId: TopicId;
    linkId: LinkId;
}>('UPDATE_LINK');

export const deleteLinkAction = topicActionCreator<{ topicId: TopicId; linkId: LinkId }>(
    'DELETE_LINK'
);

export const showEditLinkModalAction = topicActionCreator<{ topicId: TopicId; linkId: LinkId }>(
    'SHOW_EDIT_LINK_MODAL'
);

export const hideEditLinkModalAction = topicActionCreator('HIDE_EDIT_LINK_MODAL');

export const fetchLinkTitleSuccessAction = topicActionCreator<{
    topicId: TopicId;
    linkId: LinkId;
    title: string;
}>('FETCH_LINK_TITLE_SUCCESS');

export const fetchLinkTitleAsync = asyncTopicActionCreator<
    { topicId: TopicId; linkId: LinkId },
    void,
    AppState
>('FETCH_LINK_TITLE', async (params, _, getState) => {
    const { note } = getState();
    const { topicId, linkId } = params;
    const mutableNote = cloneDeep(note);
    const topicIndex = mutableNote.topics.findIndex(topic => topic.id === topicId);
    const linkIndex = mutableNote.topics[topicIndex].links.findIndex(link => link.id === linkId);

    try {
        dispatchReplayable(
            fetchLinkTitleSuccessAction({
                topicId,
                linkId,
                title: await getLinkTitle(mutableNote.topics[topicIndex].links[linkIndex].url),
            })
        );
    } catch (e) {
        dispatchReplayable(
            fetchLinkTitleSuccessAction({
                topicId,
                linkId,
                title: '',
            })
        );
    }
});

export const resetFetchLinkTitleStatusAction = topicActionCreator('RESET_FETCH_LINK_TITLE_STATUS');

export const addTagAction = topicActionCreator<{ tag: Tag; topicId: TopicId }>('ADD_TAG');

export const removeTagAction = topicActionCreator<{ tagId: TagId; topicId: TopicId }>('REMOVE_TAG');
