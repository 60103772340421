import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { auth } from 'config/firebase';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const styles = createStyles({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer',
    },
});

export interface AppNavbarProps extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

const AppNavbar: React.FunctionComponent<AppNavbarProps> = ({ classes, history }) => {
    const popupState = usePopupState({ popupId: 'new-notes-menu' });

    return (
        <div className={classes.root}>
            <AppBar>
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        onClick={() => history.push('/')}
                    >
                        Gibcast Show Notes
                    </Typography>
                    <Button color="inherit" {...bindTrigger(popupState)}>
                        New Show Notes
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem
                            onClick={() => {
                                popupState.close();
                                history.push('/note/new/sg2');
                            }}
                        >
                            Structured Gibberish (2018)
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                popupState.close();
                                history.push('/note/new/se');
                            }}
                        >
                            Special Edition
                        </MenuItem>
                    </Menu>
                    <Button color="inherit" onClick={() => auth().signOut()}>
                        Log Out
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default withRouter(withStyles(styles)(AppNavbar));
