import * as fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyDBaA0lyD4fNJxVI_BVJBcovPkZlJ6xVAs',
    authDomain: 'shownotes-a938f.firebaseapp.com',
    databaseURL: 'https://shownotes-a938f.firebaseio.com',
    projectId: 'shownotes-a938f',
    storageBucket: 'shownotes-a938f.appspot.com',
    messagingSenderId: '92590906659',
};

fb.initializeApp(firebaseConfig);
export const firebase = fb;
export const auth = fb.auth;
export const firestore = fb.firestore;
export const db = fb.firestore();

export const firebaseUiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID],
};
