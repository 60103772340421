import * as $ from 'jquery';

export function getTitleCase(title: string): Promise<string> {
    const url = 'https://proxymity.herokuapp.com/https://brettterpstra.com/titlecase/';

    return new Promise((resolve, reject) => {
        $.get(url, { title }, response => {
            resolve(response);
        }).catch(() => {
            reject();
        });
    });
}
