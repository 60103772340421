import * as $ from 'jquery';
import { Note, Topic } from 'models/notes';
import { NewLinks } from 'state/root-reducer';

export function getLinkTitle(url: string): Promise<string> {
    const proxy = 'https://proxymity.herokuapp.com/';

    return new Promise((resolve, reject) => {
        $.get(proxy + url, response => {
            let title: string = $($.parseHTML(response))
                .filter('title')
                .text();

            if (title.length) {
                resolve(title);
                return;
            }

            title = $($.parseHTML(response))
                .filter('meta')
                .filter(function() {
                    return $(this).attr('name') === 'title';
                })
                .attr('content');

            if (title.length) {
                resolve(title);
                return;
            }

            reject();
        }).catch(() => {
            reject();
        });
    });
}

export function buildNewLinks(newNote: Note, prevNewLinks: NewLinks): NewLinks {
    if (!newNote || !Array.isArray(newNote.topics)) {
        return {};
    }
    const newLinks: NewLinks = {};
    newNote.topics.forEach((topic: Topic) => {
        newLinks[topic.id] = prevNewLinks[topic.id]
            ? prevNewLinks[topic.id]
            : { url: '', ready: true };
    });
    return newLinks;
}
