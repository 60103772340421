import 'config/styles';
import 'config/datetime';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppContainer from 'app.container';
import { auth } from 'config/firebase';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

const appRootElementId = 'app-root';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1565c0',
        },
        secondary: {
            main: '#009688',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

if (process.env.NODE_ENV !== 'production') {
    console.log('Theme', theme);
}

function bootstrap() {
    render(
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <>
                    <CssBaseline />
                    <AppContainer />
                </>
            </MuiThemeProvider>
        </BrowserRouter>,
        document.getElementById(appRootElementId)
    );
}

const unsubscribe = auth().onAuthStateChanged(() => {
    unsubscribe();
    bootstrap();
});
