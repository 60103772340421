import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { AppState } from 'state/root-reducer';
import { AnyAction } from 'redux';
import { acceptIncomingNoteAction } from 'state/actions/note';
import { dispatch } from 'config/store';

const replayActionCreator = actionCreatorFactory('REPLAY');
const asyncReplayActionCreator = asyncFactory<AppState>(replayActionCreator);

export const addActionToReplayAction = replayActionCreator<{ action: AnyAction }>(
    'ADD_ACTION_TO_REPLAY'
);

export const clearReplayActionsAction = replayActionCreator('CLEAR_REPLAY_ACTIONS');

export const mergeIncomingChangesAsync = asyncReplayActionCreator<void, void, AppState>(
    'MERGE_INCOMING_CHANGES',
    (_, __, getState) => {
        const { replayActions } = getState();
        dispatch(acceptIncomingNoteAction());
        replayActions.forEach(action => {
            dispatch(action);
        });
    }
);
