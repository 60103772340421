import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CodeIcon from '@material-ui/icons/Code';
import EditIcon from '@material-ui/icons/Edit';
import MicIcon from '@material-ui/icons/Mic';
import WebIcon from '@material-ui/icons/Web';
import { dispatch, store } from 'config/store';
import { ViewMode } from 'models/notes';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { setViewModeAction } from 'state/actions/note';
import { AppState } from 'state/root-reducer';

interface NoteBottomNavbarProps extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

const styles = () => ({
    bottomNav: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
    } as CSSProperties,
});

const NoteBottomNavbar: React.FunctionComponent<NoteBottomNavbarProps> = ({ history, classes }) => {
    const [navSelected, setNavSelected] = useState<ViewMode>(undefined);

    useEffect(() => {
        return store.subscribe(() => {
            const appState: AppState = store.getState();
            if (appState.viewMode !== navSelected) {
                setNavSelected(appState.viewMode);
            }
        });
    }, [navSelected]);

    const handleNavigation = (e, value: ViewMode) => {
        dispatch(setViewModeAction({ mode: value }));
    };

    return history.location.pathname.indexOf('/note/') >= 0 ? (
        <BottomNavigation
            value={navSelected}
            onChange={handleNavigation}
            showLabels
            className={classes.bottomNav}
        >
            <BottomNavigationAction value={ViewMode.Edit} label="Edit" icon={<EditIcon />} />
            <BottomNavigationAction
                value={ViewMode.ShowTime}
                label="Show Time"
                icon={<MicIcon />}
            />
            <BottomNavigationAction
                value={ViewMode.PostShow}
                label="Post Show"
                icon={<WebIcon />}
            />
            <BottomNavigationAction value={ViewMode.Raw} label="Raw Data" icon={<CodeIcon />} />
        </BottomNavigation>
    ) : null;
};

export default withRouter(withStyles(styles)(NoteBottomNavbar));
