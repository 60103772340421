import { CircularProgress, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';

interface LoadingProps extends WithStyles<typeof styles> {}

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 4,
    },
});

const Loading: React.FunctionComponent<LoadingProps> = ({ classes }) => (
    <CircularProgress className={classes.progress} color="secondary" />
);

export default withStyles(styles)(Loading);
