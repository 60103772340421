import { TopicId } from 'models/notes';
import actionCreatorFactory from 'typescript-fsa';

export const topicActionCreator = actionCreatorFactory('SHOW_TIME');

export const toggleTopicStartAction = topicActionCreator<{ topicId: TopicId }>(
    'TOGGLE_TOPIC_START'
);

export const markRecordingStartAction = topicActionCreator<{ date: string }>(
    'MARK_RECORDING_START'
);
